<template>
    <div class='tournaments-page'>
        <h4 class='page-title'>Турниры <i class='fa fa-trophy' aria-hidden='true'></i></h4>
        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <TableSearchBar class='col-4' @onSearch='changeQueryParams($event, "search")' />
                <div class='col-4 text-right'>
                    <button class='button_add' @click.prevent='$router.push({name: "TournamentCreate"})'>
                        Добавить турнир
                    </button>
                </div>
            </div>
            <div class='input-group col-4'>

            </div>
        </div>
        <div class='widget'>
            <table class='table table-hover' v-if='tournaments'>
                <thead class='table_head'>
                <tr>
                    <th>№</th>
                    <th>Название</th>
                    <th>Создан</th>
                    <th>Обновлён</th>
                    <th>Удалён</th>
                    <th>Название англ.</th>
                    <th class='text-center actions'>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='tournament in tournaments' :key='tournament.id'>
                    <td>{{ tournament.id }}</td>
                    <td>{{ tournament.title }}</td>
                    <td>{{ tournament.created_at | normalizeCreateDate }}</td>
                    <td>{{ tournament.updated_at | normalizeCreateDate }}</td>
                    <td>{{ tournament.deleted_at | normalizeCreateDate }}</td>
                    <td>{{ tournament.title_en }}</td>
                    <td class="d-flex justify-content-center">
                        <router-link
                            class='btn mr-2 btn-primary'
                            :to="{name: 'TournamentsEdit', params: {id: tournament.id}}"
                        >
                            <span class='glyphicon glyphicon-edit'></span>
                        </router-link>
                        <a
                            class='btn btn-danger'
                            @click.prevent='deleteRow(tournament.id, tournament.code)'
                        >
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class='overflow-auto'>
                <b-pagination
                    class='paginator'
                    v-if='pagination.total'
                    v-model='pagination.current_page'
                    :total-rows='pagination.total'
                    :per-page='pagination.per_page'
                    aria-controls='my-table'
                ></b-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import PaginationMixin from '../../mixins/paginationMixin';
import TableSearchBar from '../../components/SearchBar/TableSearchBar';
import DeleteModal from '../../components/Modal/DeleteModal';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';

export default {
    name: 'Tournaments',
    components: { TableSearchBar },
    mixins: [PaginationMixin],
    data() {
        return {
            tournaments: null,
        };
    },
    watch: {},
    methods: {
        async updateRouter() {
            await this.$router.push({ query: this.routerParams });
            await this.fetchTournaments();
        },
        async fetchTournaments() {
            try {
                const { data } = await this.$axios.get('api/tournament', {
                    params: {
                        ...this.$route.query,
                    },
                });
                if (data && data.data && data.data.length) {
                    this.parsePaginationMeta(data);
                    this.tournaments = data.data;
                }
                return null;
            } catch (e) {
                alert(e);
            }
        },
        deleteRow(id, name, index) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: async (id) => {
                        try {
                            const { data } = await this.$axios.delete('/api/tournament/' + id, { id });
                            this.$toasted.success('Запись удалена!', { position: 'bottom-right' });
                            await this.fetchTournaments();
                        } catch (e) {
                            const { data } = e.response;
                            this.$toasted.error(data.message, { position: 'bottom-right' });
                        }
                    },
                }, { name: 'DeleteModal' },
            );
        },
    },
    filters: {
        normalizeCreateDate(date) {
            if (date) return dayjs(date).format('DD.MM.YYYY HH:mm');
            else return null;
        },
    },
    async created() {
        dayjs.extend(duration);
        dayjs.locale(ru);
        await this.fetchTournaments();
    },
};
</script>


<style lang="scss" scoped>

</style>
